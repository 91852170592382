@font-face {
    font-family: Equinor;
    src: url(../fonts/equinor/Equinor-Regular.woff2) format('woff2'),
        url(../fonts/equinor/Equinor-Regular.woff) format('woff');
}

@font-face {
    font-family: Equinor;
    src: url(../fonts/equinor/Equinor-Italic.woff2) format('woff2'),
        url(../fonts/equinor/Equinor-Italic.woff) format('woff');
    font-style: italic;
}

@font-face {
    font-family: Equinor;
    src: url(../fonts/equinor/Equinor-Light.woff2) format('woff2'),
        url(../fonts/equinor/Equinor-Light.woff) format('woff');
    font-weight: 200;
}

@font-face {
    font-family: Equinor;
    src: url(../fonts/equinor/Equinor-LightItalic.woff2) format('woff2'),
        url(../fonts/equinor/Equinor-LightItalic.woff) format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: Equinor;
    src: url(../fonts/equinor/Equinor-Bold.woff2) format('woff2'),
        url(../fonts/equinor/Equinor-Bold.woff) format('woff');
    font-weight: 700;
}

@font-face {
    font-family: Equinor;
    src: url(../fonts/equinor/Equinor-BoldItalic.woff2) format('woff2'),
        url(../fonts/equinor/Equinor-BoldItalic.woff) format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: Equinor;
    src: url(../fonts/equinor/Equinor-Medium.woff2) format('woff2'),
        url(../fonts/equinor/Equinor-Medium.woff) format('woff');
    font-weight: 500;
}

@font-face {
    font-family: Equinor;
    src: url(../fonts/equinor/Equinor-MediumItalic.woff2) format('woff2'),
        url(../fonts/equinor/Equinor-MediumItalic.woff) format('woff');
    font-weight: 500;
    font-style: italic;
}
